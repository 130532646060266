// TODO lots!
import React, { useState, useEffect } from 'react';
import * as classes from './video.module.scss';

const Video = ({ daylight, repo }) => {
	const [isBrowser, setIsBrowser] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsBrowser(true);
		}
	}, []);

	return (
		<figure className={daylight ? classes.videoWrap : classes.videoWrapDark}>
			{/* <img className={classes.videoFallback} src={repo.img} alt={repo.name} /> */}

			<video
				id={`${repo.name}-demo`}
				title={`${repo.displayName} demo`}
				poster={repo.img}
				tabIndex="0"
				className={`${classes.notPlaying}`}
				preload="none"
				controls
				aria-describedby={repo.name}
				loop
				muted
				onFocus={(e) => {
					e.target.controls = true;
				}}
				// on blur fires when moving between controls in firefox(not just on leaving the video). check relatedTarget is not video.
				onBlur={(e) => {
					if (!e.relatedTarget) {
						return;
					}
					if (e.relatedTarget.nodeName === 'VIDEO') {
						return;
					}
					e.target.pause();
					e.target.controls = false;
					e.target.classList.add(classes.notPlaying);
				}}
				onMouseEnter={async (e) => {
					e.persist();
					e.target.controls = true;
					try {
						await e.target.play();
						e.target.classList.remove(classes.notPlaying);
					} catch (_) {
						e.target.classList.remove(classes.notPlaying);
					}
				}}
				onMouseLeave={(e) => {
					e.target.pause();
					e.target.controls = false;
					e.target.classList.add(classes.notPlaying);
				}}
				onPlay={(e) => {
					e.target.classList.remove(classes.notPlaying);
				}}
				onPause={(e) => {
					e.target.classList.add(classes.notPlaying);
				}}
				onKeyDown={(e) => {
					if (!isBrowser) {
						return;
					}

					if (e.target !== document.activeElement) {
						return;
					}
					if (e.key !== 'Enter' && e.key !== ' ') {
						return;
					}

					e.preventDefault();

					if (e.target.paused) {
						e.target.classList.remove(classes.notPlaying);
						e.target.play();
					} else {
						e.target.classList.add(classes.notPlaying);
						e.target.pause();
					}
				}}
			>
				<source src={repo.webm} type="video/webm" />
				<source src={repo.mp4} type="video/mp4" />
				Sorry, your browser doesn't support embedded videos.
			</video>
			<div className="srText" id={repo.name}>
				{repo.videoDescription}
			</div>

			<figcaption className="srText">{repo.name}</figcaption>
		</figure>
	);
};

export default Video;
